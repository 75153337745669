<template>
	<div :style="bg" >
		<Header title="非洲矿业基金"></Header>
		<div class="column-center-content main-content" style="margin-top: 56px;">
			<div style="background-color: #FFFFFF; height: 180px; width: 100%; text-align: center;">
				<img height="120px" style="margin-top: 10px;" src="../../assets/ammf.jpg" />
			</div>
			
			<div style="display: flex; flex-direction: row; justify-content: space-between; margin-top: -40px; border-radius: 10px; background-color: #FFFFFF; width: 90%; padding: 16px; box-shadow: 2px 2px 10px #C3C3C3;">
				<div style="display: flex; flex-direction: column; justify-content: center; align-items:center; text-align: center;">
					<img width="50px" src="../../assets/money/up.png" />
					<label style="font-size: 14px; color: #409EFF; font-weight: bold; margin-top: 10px;">年化收益</label>
				</div>
				<div style="display: flex; flex-direction: column; justify-content: center; align-items:center; text-align: center;">
					<img width="50px" src="../../assets/money/money.png" />
					<label style="font-size: 14px; color: #409EFF; font-weight: bold; margin-top: 10px;">不定期分红</label>
				</div>
				<div style="display: flex; flex-direction: column; justify-content: center; align-items:center; text-align: center;">
					<img width="50px" src="../../assets/money/company.png" />
					<label style="font-size: 14px; color: #409EFF; font-weight: bold; margin-top: 10px;">推广赚收益</label>
				</div>
			</div>
			
			<div style="margin-top: 10px; border-radius: 10px; background-color: #FFFFFF; width: 90%; padding: 20px 16px 5px 16px;">
				<label style="font-size: 16px; font-weight: bold; margin-top: 5px;">期限</label>
				<div class="form-content" style="margin-top: 5px; margin-bottom: 5px; width: 100%; display: flex; flex-direction: row;">
					<mt-range
						disabled
						style="width: 90%; margin-top: 5px;"
						v-model="rangeValue"
						:min="1"
						:max="5"
						:step="2"
						:bar-height="1">
					</mt-range>
					<label style="line-height: 40px; width: 50px; text-align: right;">{{rangeValue}}  年</label>
				</div>
				
				<label style="font-size: 16px; font-weight: bold;">金额</label>
				<div class="form-content" style="margin-top: 0px; width: 100%; display: flex; flex-direction: row;">
					<v-text-field disabled color="success" style="width: 100%; padding-top: 0px;" v-model="amount"></v-text-field>
					<label style="line-height: 40px; width: 50px; text-align: right;">USDT</label>
				</div>
				
				<label style="font-size: 16px; font-weight: bold;">真实姓名</label>
				<div class="form-content" style="margin-top: 0px; width: 100%; display: flex; flex-direction: row;">
					<v-text-field disabled color="success" style="width: 100%; padding-top: 0px;" v-model="name"></v-text-field>
				</div>
				
				<label style="font-size: 16px; font-weight: bold;">联系地址</label>
				<div class="form-content" style="margin-top: 0px; width: 100%; display: flex; flex-direction: row;">
					<v-text-field disabled color="success" style="width: 100%; padding-top: 0px;" v-model="address"></v-text-field>
				</div>
			</div>
			
			<div v-show="false" class="btn-content" @click="transferAction" style="margin-bottom: 0px; margin-top: 30px;">
				<v-btn rounded block style="background-color: #009245; color: white; height: 40px;">
					互转
				</v-btn>
			</div>
			
			<div class="row-content" style="width: 90%; padding: 10px; justify-content: flex-end;">
				<div @click="downloadContract" style="font-size: 14px; color: #009245; align-items: flex-end;">
					合同下载
				</div>
				<div @click="downloadCert" style="font-size: 14px; color: #009245; align-items: flex-end; margin-left: 10px;">
					证书下载
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	import { Indicator, MessageBox } from 'mint-ui';
	
	export default {
		name: 'AddressAdd',
		components: {
			Header
		},
		created() {
			this.$request.fundComplete({
				fundId: this.fundId
			}).then((data)=>{
				Indicator.close();
				if(data && (data.code == 553 || data.code == 554)){
					// 未签名，签名提示
					MessageBox.confirm('请补全资料以下载电子合同').then(() => {
						this.$router.push({
							name: 'InfoComplete',
							params: this.fund
						})
					});
				}
			});
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				fund: JSON.parse(localStorage.getItem('cur_fund')),
				rangeValue: 3,
				amount: JSON.parse(localStorage.getItem('cur_fund')).cost,
				fundId: JSON.parse(localStorage.getItem('cur_fund')).id,
				address: JSON.parse(localStorage.getItem('cur_fund')).address,
				name: JSON.parse(localStorage.getItem('cur_fund')).name
			}
		},
		methods: {
			transferAction() {
				this.$router.push({
					name: 'FundTransfer',
					params: this.fund
				})
			},
			
			downloadCert() {
				window.open(`${window.location.origin}/api/user/fund/detail/${this.fundId}.jpg`);
			},
			
			downloadContract() {
				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				this.$request.fundComplete({
					fundId: this.fundId
				}).then((data)=>{
					Indicator.close();
					if(data && (data.code == 553 || data.code == 554)){
						this.$router.push({
							name: 'InfoComplete',
							params: this.fund
						})
					}else{
						window.open(`${window.location.origin}/api/user/fund/detail/${this.fundId}.pdf`);
					}
				});
			}
		}
	}
</script>

<style>
</style>
